.carousel .slick-slider .slick-dots {
  bottom: 20px;
}
.carousel .slick-slider .slick-dots li button::before {
  opacity: 0.75;
  color: transparent;
  font-size: 12px;
  line-height: 12px;
  width: 12px;
  height: 12px;
  border: 1px solid white;
  border-radius: 50%;
}
.carousel .slick-slider .slick-dots .slick-active button::before {
  color: #dcf836;
  opacity: 1;
}

.carousel .slick-slider .slick-dots li button:hover::before {
  opacity: 1;
  color: #dcf836;
  transition: all 0.5s ease-out;
}

.carousel .subtitle {
  display: flex;
  position: absolute;
  top: 25%;
  left: 10%;
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.carousel .subtitle li + li {
  border-left: 2px solid #fff;
}
.carousel .subtitle li {
  padding: 0 10px;
}

.carousel .title {
  position: absolute;
  top: 30%;
  left: 10%;
  font-size: 70px;
  color: #fff;
  letter-spacing: 20px;
  animation-delay: 0.3s;
}

.carousel .slide_right {
  position: absolute;
  top: 50%;
  left: 10%;
  animation-delay: 0.4s;
}
.carousel .slide_right .ant-modal-content .ant-modal-header {
  display: none;
}

.carousel .slide_right ul li + li {
  margin-left: 20px;
}

.carousel .chart-cirle {
  display: flex;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

.carousel .chart-cirle .chart-circle-l {
  animation-delay: 0.7s;
}
.carousel .chart-cirle .chart-circle-r {
  animation-delay: 0.75s;
}
