.screen {
  width: 90%;
  height: 80px;
  border: 5px solid transparent;
  border-top-color: #ff6565;
  border-radius: 70%/100px 100px 0 0;
  filter: drop-shadow(3px 3px 10px #ff6565);
}
.ghe {
  position: relative;
  /* width: 40px; */
  /* height: 40px; */
  /* border: none; */
  /* border-radius: 5px; */
  cursor: pointer;
  /* margin: 12px; */
  padding: 12px;
  /* background-color: rgb(123, 122, 122); */
  color: rgb(2, 2, 2);
}
.gheVip img {
  filter: invert(48%) sepia(100%) saturate(595%) hue-rotate(318deg)
    brightness(101%) contrast(112%) drop-shadow(0 0 8px #ff6565ad);
  /* color: rgb(228, 78, 8); */
}

.gheDaDat img {
  filter: invert(63%) sepia(91%) saturate(30%) hue-rotate(105deg)
    brightness(80%) contrast(90%) drop-shadow(0 0 0px grey);
  /* color: grey; */
  cursor: no-drop !important;
}

.gheDaDat:hover {
  cursor: no-drop;
}

.gheDangDat img {
  /* color: rgb(18, 176, 18); */
  filter: invert(63%) sepia(91%) saturate(555%) hue-rotate(105deg)
    brightness(200%) contrast(120%) drop-shadow(0 0 8px #00ffadad);
}

.ghe .sttGhe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black !important;
  padding-bottom: 6px;
}

.content-right-lower-730px{
  display: none;
}

@media screen and (max-width: 1200px) {
  .ghe {
    padding: 6px;
  }
}

@media screen and (max-width: 1000px) {
  .img-ghe {
    width: 20px;
    height: 20px;
  }
  .sttGhe {
    font-size: 10px;
  }

  .total-price {
    font-size: 20px !important;
  }

  .checkout {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 730px) {
  .grid {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content-right-upper-730px {
    display: none;
  }

  .content-right-lower-730px {
    display: flex;
    justify-content: space-around;
    flex-wrap:nowrap ;
    padding: 20px 20px;
  }

  .ant-table-wrapper {
    max-width: 40% !important;
    background-color: transparent;
  }
}
