@import url(https://fonts.googleapis.com/css2?family=Alata&display=swap);
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Alata", sans-serif;
}

a {
  font-size: 14px;
  line-height: 1.7;
  color: #d1d5d0;
  margin: 0px;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #dcf836;
  transition: all 0.5s linear;
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  transition: all 0.5s ease-out;
}

p {
  font-size: 14px;
  line-height: 1.7;
  color: #d1d5d0;
  margin: 0px;
  transition: all 0.5s ease-out;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

span {
  color: #fff;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/

button {
  outline: none !important;
  border: none;
  background: transparent;
  color: #fff;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.pink-color {
  color: #dd003f;
}

.bg-pink-color {
  background-color: #dd003f;
}

.bg-pink-color-hover:hover {
  background-color: #dd003f;
}

.bg-pink-color-focus:focus {
  background-color: #dd003f;
}

.yellow-color {
  color: #dcf836;
  transition: all 0.5s ease-out;
}

.yellow-color-hover:hover {
  transition: all 0.5s ease-out;
  color: #dcf836;
}

.bg-gray-blue-color {
  background-color: #0f2133;
}

.bg-dark-color {
  background-color: #020d18;
}

.bg-dark-blue-color {
  background-color: #06121e;
}

.bg-light-blue-color {
  background-color: #1c3d5d;
}

.bg-light-blue-color-hover:hover {
  background-color: #1c3d5d;
}

.bg-light-blue-color-focus:focus {
  background-color: #1c3d5d;
}

.title-color {
  transition: all 0.5s ease-out;
  color: #4280bf;
}

.title-color-hover:hover {
  transition: all 0.5s ease-out;
  color: #4280bf;
}

.admin-film .ant-table-column-sorters span {
  color: #000;
}

.movie-item .mv-img::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: inset -5px -50px 100px -15px #000000;
  -webkit-box-shadow: inset -5px -50px 100px -15px #000000;
  -moz-box-shadow: inset -5px -50px 100px -15px #000000;
  -o-box-shadow: inset -5px -50px 100px -15px #000000;
  transition: all 0.5s ease-out;
}

.movie-item:hover .mv-img {
  opacity: 0.25;
  transition: all 0.5s ease-out;
}

.movie-item .mv-img {
  transition: all 0.5s ease-in;
}

.movie-item:hover .hvr-inner {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.list-movie .slick-slider .slick-dots {
  bottom: auto;
  top: -25px;
  text-align: end;
  right: 20px;
}

.list-movie .slick-slider .slick-dots li {
  margin-right: 1px;
}

.list-movie .slick-slider .slick-dots li button::before {
  opacity: 0.75;
  color: #fff;
  font-size: 11px;
}

.list-movie .slick-slider .slick-dots .slick-active button::before {
  color: #dcf836;
  opacity: 1;
}

.list-movie .slick-slider .slick-dots li button:hover::before {
  opacity: 1;
  color: #dcf836;
  transition: all 0.5s ease-out;
}

.trailers .trailer-left .slick-arrow {
  display: none !important;
}

.trailers .trailer-right .slick-arrow {
  left: 48%;
}

.trailers .trailer-right .slick-arrow.slick-prev {
  top: 1%;
}

.trailers .trailer-right .slick-arrow.slick-prev::before {
  content: "⮝";
  font-size: 30px;
}

.trailers .trailer-right .slick-arrow.slick-next {
  top: unset;
  bottom: 1%;
}

.trailers .trailer-right .slick-arrow.slick-next::before {
  content: "⮟";
  font-size: 30px;
}

.trailers .trailer-right .slick-arrow:hover::before {
  color: #dcf836;
  transition: all 0.5s ease-out;
}

.trailers .trailer-right .slick-arrow::before {
  transition: all 0.5s ease-out;
}

.trailers .trailer-right .trailer-film {
  display: flex !important;
  align-items: center;
}

.trailers .trailer-right .slick-list .slick-track {
  max-height: 400px;
}

.trailers .trailer-left iframe {
  width: 100%;
  height: 435px;
}

@media screen and (max-width: 768px) {
  .trailers .trailer-left iframe {
    height: 345px;
  }

  .trailers .trailer-film {
    padding: 10px;
  }

  .trailers .trailer-right .trailer-film {
    display: block !important;
  }

  .trailers .trailer-right .slick-arrow {
    left: 44%;
  }

  .trailers .trailer-right h3 {
    font-size: 12px;
  }

  .trailers .trailer-right img {
    width: 100%;
    height: 55px;
  }
}

@media screen and (max-width: 400px) {
  .trailers .trailer-left iframe {
    height: 300px;
  }

  .trailers .trailer-film {
    padding: 10px;
  }

  .trailers .trailer-right h3 {
    font-size: 5px;
  }
}

.carousel .slick-slider .slick-dots {
  bottom: 20px;
}

.carousel .slick-slider .slick-dots li button::before {
  opacity: 0.75;
  color: transparent;
  font-size: 12px;
  line-height: 12px;
  width: 12px;
  height: 12px;
  border: 1px solid white;
  border-radius: 50%;
}

.carousel .slick-slider .slick-dots .slick-active button::before {
  color: #dcf836;
  opacity: 1;
}

.carousel .slick-slider .slick-dots li button:hover::before {
  opacity: 1;
  color: #dcf836;
  transition: all 0.5s ease-out;
}

.carousel .subtitle {
  display: flex;
  position: absolute;
  top: 25%;
  left: 10%;
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.carousel .subtitle li + li {
  border-left: 2px solid #fff;
}

.carousel .subtitle li {
  padding: 0 10px;
}

.carousel .title {
  position: absolute;
  top: 30%;
  left: 10%;
  font-size: 70px;
  color: #fff;
  letter-spacing: 20px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.carousel .slide_right {
  position: absolute;
  top: 50%;
  left: 10%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.carousel .slide_right .ant-modal-content .ant-modal-header {
  display: none;
}

.carousel .slide_right ul li + li {
  margin-left: 20px;
}

.carousel .chart-cirle {
  display: flex;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

.carousel .chart-cirle .chart-circle-l {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.carousel .chart-cirle .chart-circle-r {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.app {
  width: 100%;
  background-image: url(/static/media/bg.6b529071.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.app .mobile .slick-slider {
  position: absolute;
  padding: 1.5% 29.3% 0 29.3%;
  top: 0;
  left: 0;
  width: 100%;
}

.app .mobile .slick-list {
  border-radius: 20px;
}

.app .mobile .slick-arrow {
  display: none !important;
}

.he-thong-rap .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 80px;
}

.he-thong-rap
  .ant-menu-inline
  > .ngay-chieu.ant-menu-submenu
  > .ant-menu-submenu-title {
  height: 50px;
  border-radius: 10px;
}

.he-thong-rap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #dcf836;
}

.he-thong-rap .ant-tabs-tab:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-title-content:hover,
.ant-menu-item a:hover {
  color: #dcf836;
}

.ant-menu-title-content,
.ant-menu-submenu-arrow,
.ant-tabs,
.ant-menu-item a {
  color: #fff;
}

.ant-menu-sub.ant-menu-inline {
  background: none;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
  color: #dcf836;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: initial;
  color: #dcf836;
}

.he-thong-rap .scroll-cum-rap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #020d18;
  border-radius: 10px;
  background-color: #0f2133;
}

.he-thong-rap .scroll-cum-rap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.he-thong-rap .scroll-cum-rap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dcf836;
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4) 50%,
    transparent,
    transparent
  );
}

.he-thong-rap .film:hover .ten-phim {
  color: #dcf836;
}

.he-thong-rap
  .ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding: 0;
}

@media (max-width: 640px) {
  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding-left: 5px !important;
  }
}

/*//////////////////////////////////////////////////////////////////
    [ Utility ]*/

.txt1 {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
    [ login ]*/

.container-login100 {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  position: relative;
  width: 390px;
  background: #e8f0fe;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------
    [ Form ]*/

.register-form {
  position: absolute;
  top: 12%;
  left: 15%;
  transition: all 0.8s linear;
}

.register-form-hide-right {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.login100-form-hide-left {
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
  opacity: 0;
  transition: all 0.8s linear;
}

.login100-form {
  width: 100%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.login100-form-title {
  display: block;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login100-form-title i {
  font-size: 60px;
}

.login100-form-img {
  width: 100%;
  height: 70px;
  object-fit: contain;
  margin-bottom: 1rem;
}

/*------------------------------------------------------------------
    [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 37px;
}

.input100 {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;
  background: #6a7dfe;
  background: linear-gradient(to right, #21d4fd, #b721ff);
}

.focus-input100::after {
  font-size: 15px;
  color: #999999;
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after,
.input100:valid + .focus-input100::after {
  top: -15px;
}

.input100:focus + .focus-input100::before,
.input100:valid + .focus-input100::before {
  width: 100%;
}

/*---------------------------------------------*/

.btn-show-pass {
  font-size: 15px;
  color: #999999;
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 0;
  padding-right: 5px;
  cursor: pointer;
  transition: all 0.4s;
}

.btn-show-pass:hover {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

.btn-show-pass.active {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

/*------------------------------------------------------------------
    [ Button ]*/

.container-login100-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  top: 0;
  left: -100%;
  transition: all 0.4s;
}

.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------
    [ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 33px 15px;
  }
}

/*//////////////////////////////////////////////////////////////////
    [ Utility ]*/

.txt1 {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
    [ login ]*/

.container-login100 {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  position: relative;
  width: 390px;
  background: #e8f0fe;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------
    [ Form ]*/

.register-form {
  position: absolute;
  top: 12%;
  left: 15%;
  transition: all 0.8s linear;
}

.register-form-hide-right {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.login100-form-hide-left {
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
  opacity: 0;
  transition: all 0.8s linear;
}

.login100-form {
  width: 100%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.login100-form-title {
  display: block;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login100-form-title i {
  font-size: 60px;
}

.login100-form-img {
  width: 100%;
  height: 70px;
  object-fit: contain;
  margin-bottom: 1rem;
}

/*------------------------------------------------------------------
    [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 25px;
}

.input100 {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;
  background: #6a7dfe;
  background: linear-gradient(to right, #21d4fd, #b721ff);
}

.focus-input100::after {
  font-size: 15px;
  color: #999999;
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after,
.input100:valid + .focus-input100::after {
  top: -15px;
}

.input100:focus + .focus-input100::before,
.input100:valid + .focus-input100::before {
  width: 100%;
}

/*---------------------------------------------*/

.btn-show-pass {
  font-size: 15px;
  color: #999999;
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 0;
  padding-right: 5px;
  cursor: pointer;
  transition: all 0.4s;
}

.btn-show-pass:hover {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

.btn-show-pass.active {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

/*------------------------------------------------------------------
    [ Button ]*/

.container-login100-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  top: 0;
  left: -100%;
  transition: all 0.4s;
}

.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------
    [ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 33px 15px;
  }
}

.sticky {
  background-color: #0f2133;
  position: fixed;
  opacity: 1;
  transition: all 0.5s ease-out;
  z-index: 99;
}

@media (min-width: 640px) {
  .sticky .relative.flex {
    height: 5rem;
  }
}

.screen {
  width: 90%;
  height: 80px;
  border: 5px solid transparent;
  border-top-color: #ff6565;
  border-radius: 70%/100px 100px 0 0;
  -webkit-filter: drop-shadow(3px 3px 10px #ff6565);
  filter: drop-shadow(3px 3px 10px #ff6565);
}

.ghe {
  position: relative;
  /* width: 40px; */
  /* height: 40px; */
  /* border: none; */
  /* border-radius: 5px; */
  cursor: pointer;
  /* margin: 12px; */
  padding: 12px;
  /* background-color: rgb(123, 122, 122); */
  color: rgb(2, 2, 2);
}

.gheVip img {
  -webkit-filter: invert(48%) sepia(100%) saturate(595%) hue-rotate(318deg)
    brightness(101%) contrast(112%) drop-shadow(0 0 8px #ff6565ad);
  filter: invert(48%) sepia(100%) saturate(595%) hue-rotate(318deg)
    brightness(101%) contrast(112%) drop-shadow(0 0 8px #ff6565ad);
  /* color: rgb(228, 78, 8); */
}

.gheDaDat img {
  -webkit-filter: invert(63%) sepia(91%) saturate(30%) hue-rotate(105deg)
    brightness(80%) contrast(90%) drop-shadow(0 0 0px grey);
  filter: invert(63%) sepia(91%) saturate(30%) hue-rotate(105deg)
    brightness(80%) contrast(90%) drop-shadow(0 0 0px grey);
  /* color: grey; */
  cursor: no-drop !important;
}

.gheDaDat:hover {
  cursor: no-drop;
}

.gheDangDat img {
  /* color: rgb(18, 176, 18); */
  -webkit-filter: invert(63%) sepia(91%) saturate(555%) hue-rotate(105deg)
    brightness(200%) contrast(120%) drop-shadow(0 0 8px #00ffadad);
  filter: invert(63%) sepia(91%) saturate(555%) hue-rotate(105deg)
    brightness(200%) contrast(120%) drop-shadow(0 0 8px #00ffadad);
}

.ghe .sttGhe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: black !important;
  padding-bottom: 6px;
}

.content-right-lower-730px{
  display: none;
}

@media screen and (max-width: 1200px) {
  .ghe {
    padding: 6px;
  }
}

@media screen and (max-width: 1000px) {
  .img-ghe {
    width: 20px;
    height: 20px;
  }

  .sttGhe {
    font-size: 10px;
  }

  .total-price {
    font-size: 20px !important;
  }

  .checkout {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 730px) {
  .grid {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-right-upper-730px {
    display: none;
  }

  .content-right-lower-730px {
    display: flex;
    justify-content: space-around;
    flex-wrap:nowrap ;
    padding: 20px 20px;
  }

  .ant-table-wrapper {
    max-width: 40% !important;
    background-color: transparent;
  }
}

.ant-modal-footer button:first-child{
  background-color:red
}

.ant-modal-root .ant-modal-mask{
  background-color:rgba(0,0,0,0.1) !important
}

.ant-select-selection-item{
  color:black !important
}

