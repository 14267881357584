/*//////////////////////////////////////////////////////////////////
    [ Utility ]*/
.txt1 {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
    [ login ]*/

.container-login100 {
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  position: relative;
  width: 390px;
  background: #e8f0fe;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------
    [ Form ]*/
.register-form {
  position: absolute;
  top: 12%;
  left: 15%;
  transition: all 0.8s linear;
}

.register-form-hide-right {
  transform: translateX(200%);
  opacity: 0;
}

.login100-form-hide-left {
  transform: translateX(-150%);
  opacity: 0;
  transition: all 0.8s linear;
}
.login100-form {
  width: 100%;
  transform: translateX(0);
  opacity: 1;
}

.login100-form-title {
  display: block;

  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}
.login100-form-title i {
  font-size: 60px;
}
.login100-form-img {
  width: 100%;
  height: 70px;
  object-fit: contain;
  margin-bottom: 1rem;
}

/*------------------------------------------------------------------
    [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 25px;
}

.input100 {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #6a7dfe;
  background: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  background: -o-linear-gradient(to right, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  background: linear-gradient(to right, #21d4fd, #b721ff);
}

.focus-input100::after {
  font-size: 15px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after,
.input100:valid + .focus-input100::after {
  top: -15px;
}

.input100:focus + .focus-input100::before,
.input100:valid + .focus-input100::before {
  width: 100%;
}

/*---------------------------------------------*/
.btn-show-pass {
  font-size: 15px;
  color: #999999;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 0;
  padding-right: 5px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-show-pass:hover {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

.btn-show-pass.active {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

/*------------------------------------------------------------------
    [ Button ]*/
.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: -webkit-linear-gradient(
    to left,
    #21d4fd,
    #b721ff,
    #21d4fd,
    #b721ff
  );
  background: -o-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------
    [ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 33px 15px;
  }
}
