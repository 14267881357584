.sticky {
  background-color: #0f2133;
  position: fixed;
  opacity: 1;
  transition: all 0.5s ease-out;
  z-index: 99;
}

@media (min-width: 640px) {
  .sticky .relative.flex {
    height: 5rem;
  }
}
