.ant-modal-footer button:first-child {
  background-color: red;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-select-selection-item {
  color: black !important;
}
